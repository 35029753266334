import React, {createContext, useState} from 'react'

const GlobalContext = (props) => {
    const [state, setState] = useState({})
    return (
        <div>
            <AppContext.Provider value={[state, setState]}>
                 {props.children}
            </AppContext.Provider>
        </div>
    )
}

export default GlobalContext
export const AppContext = createContext()