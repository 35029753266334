import React from 'react'

export default function Notificacion(props) {
    const notificationType = () => {
        switch (props.tipo) {
            case 'none':
                return 'w-full bg-gray-400'
            case 'error':
                return 'w-full bg-red-600 text-white'
            case 'ok':
                return 'w-full bg-green-400'
            default:
                return 'w-full bg-gray-400'
        }
    }

    return (
        <div className={notificationType()}>
            <div className="w-11/12 mx-auto py-3 text-sm font-bold shadow-inner-sm text-center">
                {props.children}
            </div>
        </div>
    )
}
