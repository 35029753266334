import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../common/GlobalContext'
import CONS from '../../common/Constants'
import { toast } from 'react-toastify';
import md5 from 'md5'
import InputComponent from './components/InputComponent'
import LoginCard from './components/LoginCard'
import bg from '../../assets/img/crux-australis.jpg'
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

export default function LoginPage() {
    const apiLDAP = process.env.REACT_APP_API_URL_LDAP;
    const userInput = useRef();
    const userPass = useRef();
    const [state, setState] = useContext(AppContext);
    const history = useHistory();

    const doLogin = async () => {
        const user = userInput.current.value;
        const password = userPass.current.value;

        if (!user || !password) {
            toast.error('Usuario o contraseña incorrectos.');
            return;
        }

        const loginData = { user, password };
        console.log('Data sent to API:', loginData); // Mostrar los datos enviados a la API en la consola

        try {
            const response = await fetch(apiLDAP, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginData)
            });

            const data = await response.json();
            console.log('API Response:', data); // Mostrar la respuesta de la API en la consola

            if (data.auth === 1) {
                console.log('usuario valido');
                setState({ ...state, users_session: { username: user } });
                sessionStorage.setItem('IDUsuario', data.id); // Guardar response.id en sessionStorage
                history.push('/configurar-tramo');
            } else {
                toast.error('Usuario o contraseña incorrectos.');
            }
        } catch (error) {
            toast.error('Error en la conexión con el servidor.');
            console.error(error);
        }
    };

    useEffect(() => {
        const getUsers = async () => {
            const url = `${CONS.apiCruce}/api/Users`;

            try {
                const response = await fetch(url);
                const data = await response.json();
                setState({ usuarios: data });
            } catch (error) {
                console.log(error);
            }
        };

        if (!state.usuarios) {
            getUsers();
        }
    }, [state, setState]);

    return (
        <div className="relative z-0 w-full h-full pt-10">
            <LoginCard>
                <InputComponent type="text" ref={userInput} name="user" placeholder="Usuario">
                    <svg className="w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                </InputComponent>

                <InputComponent type="password" ref={userPass} name="pass" placeholder="Contraseña">
                    <svg className="w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                </InputComponent>

                <div className="w-full block mb-5">
                    <button onClick={doLogin} className="w-full rounded block bg-blue-800 uppercase text-white py-4 transition-colors hover:bg-blue-900" style={{ backgroundColor: "#0684c6" }}>INGRESAR</button>
                </div>

                <div>
                    <small>Estás ejecutando esta aplicación en modo <b>{process.env.NODE_ENV}</b>.</small>
                    <form>
                        <input type="hidden" defaultValue={process.env.REACT_APP_NOT_SECRET_CODE} />
                    </form>
                </div>
            </LoginCard>

            <div className="absolute object-cover w-full h-full top-0 left-0 z-0">
                <img className="object-cover w-full h-full" src={bg} alt="Transbordadora Austral Broom" />
            </div>
        </div>
    );
}
