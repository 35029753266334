const CONS = {
    apiCruce: process.env.REACT_APP_API_URL_API_CRUCE,
    destinos: {
        "Punta Arenas": [
            { nombre_sitio: 'Puerto Williams', id_tramo: 3, id_sitio: 3 },
            { nombre_sitio: 'Isla Magdalena', id_tramo: 9, id_sitio: 9 },
            { nombre_sitio: 'Bahía Punta Arenas', id_tramo: 11, id_sitio: 11 },
            { nombre_sitio: 'Porvenir', id_tramo: 1, id_sitio: 2 }
        ],

        "Porvenir": [
            { nombre_sitio: 'Punta Arenas', id_tramo: 2, id_sitio: 1 }
        ],

        "Puerto Williams": [
            { nombre_sitio: 'Punta Arenas', id_tramo: 4, id_sitio : 1 }
        ],

        // "Bahía Azul": [
        //     { nombre_sitio: 'Bahía Azul', id_tramo: 5, id_sitio: 6}
        // ],

        // "Punta Delgada": [
        //     { nombre_sitio: 'Punta Delgada', id_tramo: 6, id_sitio: 5 }
        // ],

        // "Río Verde": [
        //     { nombre_sitio: 'Ponsomby', id_tramo: 7, id_sitio:8 }
        // ],

        // "Ponsomby": [
        //     { nombre_sitio: 'Río Verde', id_tramo: 8 , id_sitio:7}
        // ],
        
        "Puerto Natales": [
            {nombre_sitio: 'Norte', id_tramo: 41, id_sitio: [51,46,56] }  //! preguntar si es un arreglo 
            //{nombre_sitio: 'Norte', id_tramo: [41,42,43], id_sitio: [51,46,56] } 
        ],

        "Puerto Edén": [
            { nombre_sitio: 'Norte', id_tramo: 51, id_sitio:[46,56]},
            { nombre_sitio: 'Sur', id_tramo: 51, id_sitio: 41}
        ],

        "Caleta Tortel": [
            { nombre_sitio: 'Norte', id_tramo: 46, id_sitio:56 },
            { nombre_sitio: 'Sur', id_tramo: 46, id_sitio: [41,51]}
        ],

        "Puerto Yungay": [
            { nombre_sitio: 'Sur', id_tramo: 56, id_sitio:[41,51,46]}
        ],
        
        // "Puerto Yungay": [
        //     { nombre_sitio: 'Puerto Natales', id_tramo: 53, id_sitio:41 }, 
        //     { nombre_sitio: 'Puerto Edén', id_tramo: 55, id_sitio:51 },
        //     { nombre_sitio: 'Caleta Tortel', id_tramo: 56, id_sitio: 46 }
        // ],

        // "Puerto Natales": [
        //     { nombre_sitio: 'Puerto Edén', id_tramo: 41, id_sitio: 51 }, 
        //     { nombre_sitio: 'Caleta Tortel', id_tramo: 42, id_sitio:46 }, 
        //     { nombre_sitio: 'Puerto Yungay', id_tramo: 43, id_sitio: 56 }
        // ],

        // "Puerto Edén": [
        //     { nombre_sitio: 'Caleta Tortel', id_tramo: 44, id_sitio:46 },
        //     { nombre_sitio: 'Puerto Yungay', id_tramo: 45, id_sitio: 56 },
        //     { nombre_sitio: 'Puerto Natales', id_tramo: 51, id_sitio: 41 }
        // ],

        // "Caleta Tortel": [
        //     { nombre_sitio: 'Norte', id_tramo: 46, id_sitio:56 },
        //     { nombre_sitio: 'Puerto Natales', id_tramo: 52, id_sitio: 41 },
        //     { nombre_sitio: 'Puerto Edén', id_tramo: 54, id_sitio:51 }
        // ],

        // "Puerto Yungay": [
        //     { nombre_sitio: 'Puerto Natales', id_tramo: 53, id_sitio:41 }, 
        //     { nombre_sitio: 'Puerto Edén', id_tramo: 55, id_sitio:51 },
        //     { nombre_sitio: 'Caleta Tortel', id_tramo: 56, id_sitio: 46 }
        // ],
    }
}

export default CONS