/**
 * InputComponent
 * Para atomizar un poco más el código y poder hacer animaciones
 * más complejas o para más estados.
 */
import React from 'react'

 const InputComponent = React.forwardRef((props, ref) => {

    return (
        <div className="w-full block mb-5 relative z-10 "> 

            <div className="absolute right-3 h-full flex items-center">
                {props.children}
            </div>
            
            <input 
                autoComplete="off" 
                ref={ref} className="w-full rounded block border-gray-200 shadow"
                type={props.type} 
                name={props.name} 
                placeholder={props.placeholder} 
                />
        
        </div>
    )
})
export default InputComponent