import { useLocation, Link } from 'react-router-dom'

export default function ButtonComponent({ title, children, link }) {

    const location = useLocation()
    const active = location.pathname === link

    return (
        <Link to={link} className="w-full">
            <div className={`w-full flex justify-center flex-wrap pb-2 pt-3 ${active ? 'text-white bg-blue-900 shadow-xl' : 'text-blue-300'}`}>
                {children}
                <div className="font-light text-xs text-center w-full block px-2">
                    {title}
                </div>
            </div>
        </Link>
    )
}
