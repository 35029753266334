/**
 * 
 * APP.JS
 * Punto de entrada de la aplicación. Aqui agregamos un contexto 
 * para poder mantener un estado accesible y modificable 
 * dentro de toda la aplicación.
 * 
 * Cargamos ademas el Router para poder generar las URLS necesarias
 * 
 * Última revision: 13/02/20201 12:00  - Fabián Vásquez
 * 
 */

import GlobalContext, { AppContext } from './common/GlobalContext'              // para mantener un estado global.
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'       // manejo de rutas
import {ToastContainer, Slide} from 'react-toastify'                            // alertas y notificaciones


/**
 * Importamos las paginas para retornar segun la ruta solicitada:
 * 
 *    /login              : Inicio de sesión.
 *    /configurar-tramo   : Configuracion de origen, destino y fecha.
 *    /seleccion-cruce    : Seleccionar el cruce respuesta de la configuración.
 *    /scan-camara        : Escaneo y proceso de QR por camara.
 *    /acerca-de          ; Información sobre la aplicación. (licencias, autores, FAQ...)
 * 
 */

import LoginPage from './pages/LoginPage/LoginPage'
import ConfigurarTramoPage from './pages/ConfigurarTramoPage/ConfigurarTramoPage'
import SeleccionarCruce from './pages/SeleccionarCruce/SeleccionarCruce'
import EscanerPage from './pages/EscanerPage/EscanerPage'
import { useContext } from 'react'


function App() {

  return (
    /**
     * Todo lo que está como hijo o anidado en Global context tiene 
     * acceso al estado de la aplicación.
     * 
     * NOTA: Los estados son efimeros y viven hasta que se recargue la app.
     *       Para datos persistente, utilizamos window.localStorage
     * 
     * Considerar en algun momento, utilizar una api para almacenar el uso 
     * individual de los clientes para generar estadisticas y tener un mejor
     * control sobre el funcionamiento de la app
     * 
     * Envolvemos la app en un div sin scrolls para darle una 
     * sensación más nativa a la hora del uso y tener control
     * sobre las áreas 'scrolleables' dentro de la app.
     */

    <GlobalContext>
      <Router>
        <div className="w-full h-screen bg-gray-900 overflow-none">
          <Switch>
            <Route path={`/`} exact component={LoginPage} />
            {/* <Route path={`/`} exact component={ConfigurarTramoPage} /> */}
            <Route path={`/configurar-tramo`} exact component={ConfigurarTramoPage} />
            <Route path={`/seleccionar-cruce`} exact component={SeleccionarCruce} />
            <Route path={`/escaner`} exact component={EscanerPage} />
            {/* <Route path={`/tramo`} exact component={Tramo} />
            />
            <Route path={`/about`} exact component={About} /> */}
          </Switch>
          <ToastContainer
                transition={Slide}
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
      </Router>
    </GlobalContext>
  )
}

export default App;
