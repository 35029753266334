import React, { useContext } from 'react'
import { AppContext } from '../../common/GlobalContext'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'

const Cruce = (props) => {

    const splitTramo = props.tramo.split('->')
    const [state, setState] = useContext(AppContext)
    const history = useHistory()

    const setCruceScaneo = () => {

        const cruceSeleccionado = {
            id_cruce: props.idCruce,
            tramo: props.tramo,
            cupoPasajero: props.cupoPasajero,
            cupoVehiculo: props.cupoVehiculo,
            horaPresentacion: props.horarioPresentacion,
            horaCruce: props.horarioCruce,
            nombreNave: props.nombreNave,
            id_tramo: props.idTramo
        }

        setState({ ...state, cruceSeleccionado: cruceSeleccionado })
        localStorage.setItem('estado', JSON.stringify({ ...state, cruceSeleccionado: cruceSeleccionado }))
        return history.push('/escaner')
    }

    return (
        <div onClick={setCruceScaneo} className="px-4 pt-3 pb-1 sm:px-6">
            
            <div className="flex items-center flex-col">
                <div className="flex justify-end items-center text-xl font-medium text-white truncate w-full">
                    {splitTramo[0]}
                    <svg className="w-6 h-6 mx-2  text-yellow-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    {splitTramo[1]}
                </div>
            </div>

            <div className="w-full justify-between flex py-2 text-xs text-white">
                
                <div className="flex items-center bg-black bg-opacity-20 shadow-inner-lg border-b border-gray-500 p-1 rounded">
                    <svg className="w-5 h-5 mr-2 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                    </svg>
                    {props.nombreNave}
                </div>

                <div className="flex items-center bg-black bg-opacity-20 shadow-inner-lg border-b border-gray-500 p-1 rounded">
                    <svg className="w-5 h-5 mr-2 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>

                    <Moment format="DD/MM" add={{ hours: 3 }}>
                        {props.horarioCruce}
                    </Moment>
                </div>


                <div className="flex items-center bg-black bg-opacity-20 shadow-inner-lg border-b border-gray-500 p-1 rounded ">
                    <svg className="w-5 h-5 mr-1 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="font-black text-sm mr-2 text-yellow-600">Z</span>
                    <Moment format="HH:mm" add={{ hours: 3 }}>
                        {props.horarioCruce}
                    </Moment>
                </div>


                <div className="flex items-center bg-black bg-opacity-20 shadow-inner-lg border-b border-gray-500 p-1 rounded ">
                    <svg className="w-5 h-5 mr-1 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="font-black text-sm mr-2 text-yellow-600">P</span>
                    <Moment format="HH:mm" add={{ hours: 3 }}>
                        {props.horarioPresentacion}
                    </Moment>
                </div>

            </div>

        </div>
    )
}

export default Cruce