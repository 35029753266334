import React from 'react'
import FooterComponent from '../FooterComponent/FooterComponent'
import HeaderComponent from '../HeaderComponent/HeaderComponent'

export default function AppShell({ children, titulo, subtitulo, capturador }) {

    return (
            <div className="w-full h-full bg-gray-200 overflow-none flex flex-col">
                <HeaderComponent titulo={titulo} subtitulo={subtitulo} capturador={capturador} />
                <div className="h-full overflow-y-auto">
                    {children}
                </div>
                <FooterComponent />
            </div>
    )
}
