import { useState } from 'react'

export default function HeaderComponent({ titulo, subtitulo, capturador }) {

    const [tipo, setTipo] = useState(localStorage.getItem('tipoCamara'))

    const cambiarCapturador = () => {

        if (tipo === 'camara') {
            capturador('laser')
            setTipo('laser')
            localStorage.setItem('tipoCamara', 'laser')
        }

        else if (tipo === 'laser') {
            capturador('camara')
            setTipo('camara')
            localStorage.setItem('tipoCamara', 'camara')
        }

        else {
            capturador('camara')
            setTipo('camara')
            localStorage.setItem('tipoCamara', 'camara')
        }

        localStorage.setItem('tipoCamara', tipo)
    }

    return (
        <div class="w-full py-5 flex flex-wrap items-center bg-blue-900 justify-center relative z-30 shadow-lg" style={{ backgroundColor: "#0684c6" }}>
            { titulo &&
                <div className="font-black  text-white uppercase block leading-none">{titulo}</div>
            }
            { subtitulo &&
                <div className="font-semibold text-yellow-600 text-xs  uppercase leading-none mt-1">{subtitulo}</div>
            }

            { capturador &&
                <div onClick={cambiarCapturador} className="bg-black bg-opacity-20 cursor-pointer absolute top-0 right-0 flex w-20 h-full items-center border-l justify-center border-blue-800 flex-wrap text-white">
                    <svg className="w-7 h-7 m-0"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z" clipRule="evenodd" />
                        <path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z" />
                    </svg>
                    <span className="text-xs block w-full text-center -mt-3 uppercase p-0 leading-0">{tipo}</span>
                </div>
            }
        </div>
    )
}
